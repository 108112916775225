@font-face {
    font-family: "AcuminPro-Bold";
    src: url("./fonts/AcuminPro-Bold.ttf");
}
@font-face {
    font-family: "Helvetica-Regular";
    src: url("./fonts/Helvetica-Regular.ttf");
}
@font-face {
    font-family: "Helvetica-Bold";
    src: url("./fonts/Helvetica-Bold.ttf");
}
@font-face {
    font-family: "HelveticaNeue-Bold";
    src: url("./fonts/HelveticaNeue-Bold.ttf");
}
@font-face {
    font-family: "HelveticaNeue-Regular";
    src: url("./fonts/HelveticaNeue-Regular.ttf");
}
@font-face {
    font-family: "HelveticaNeue-Light";
    src: url("./fonts/HelveticaNeue-Light.ttf");
}
@font-face {
    font-family: "HelveticaNeue-Medium";
    src: url("./fonts/HelveticaNeue-Medium.ttf");
}
@font-face {
    font-family: "OneMobilePop";
    src: url("./fonts/OneMobilePop.ttf");
}
@font-face {
    font-family: "NanumBarunpenB";
    src: url("./fonts/NanumBarunpenB.ttf");
}
@font-face {
    font-family: "ByulbitchaR";
    /*src: url('./fonts/ByulbitchaR.ttf');*/
}
@font-face {
    font-family: "AppleLiGothicMedium";
    /*src: url('./fonts/AppleLiGothicMedium.ttf');*/
}
